import { Layer, Map, Source, MapRef, Marker } from "react-map-gl";
import { MAPBOX_TOKEN, initialViewState, layerSearchRouteStyle } from "../../constant";
import { Button, Form, Input, Row, Upload } from "antd";
import { useRef, useState } from "react";
import { CMarkerType } from "../../constant/type";
import { useForm } from "antd/es/form/Form";
import { UploadOutlined } from "@ant-design/icons";
import { LngLat, LngLatBounds } from "mapbox-gl";
import axios from "axios";
import { decode, encode } from "@googlemaps/polyline-codec";
import { decodeGeometry } from "../../helper/LocationHelper";

export default function ViewGeometry() {
    const [lineCreate, setLineCreate] = useState<any>();
    const [markersStart, setMarkersStart] = useState<CMarkerType>();
    const [markersEnd, setMarkersEnd] = useState<CMarkerType>();
    const mapRef = useRef<MapRef>(null);
    const [formSample] = useForm();

  
    const [isShowMarker, setIsShowMarker] = useState(false);
    const [formView] = useForm();
    const [markersView, setMarkersView] = useState<any>();
    const handleKeyUpEnd = (event: any) => {
        if (event.keyCode === 13) {
            const viewLocation = formView.getFieldValue('viewLocationField');
            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(viewLocation)) {
                const [lat, lon] = viewLocation.split(',').map(parseFloat);
                console.log(lat, lon);
                formView.setFields([
                    {
                        name: 'viewLocationField',
                        errors: []
                    }
                ])
                setMarkersView({
                    lat,
                    lon,
                })
                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
            }
            else formView.setFields([
                {
                    name: 'viewLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])

        }

    }

    console.log('markersView', markersView);
    
    return (
        <div className='h-screen flex flex-row items-stretch' >
            {/* <h1>Grid System</h1> */}

            <div className='w-[16rem] p-2 overflow-auto flex flex-col'>
                <Form form={formView}>
                    <Row>

                        <Form.Item name='viewLocationField' rules={[
                            {
                                required: true,
                                validateTrigger: 'onFinish',
                                message: 'Vui lòng chọn/nhập toạ độ'
                            },
                        ]} >
                            <Input allowClear placeholder='Nhập toạ độ (vĩ độ - lat, kinh độ - lon)' />
                        </Form.Item>
                        <Button onClick={() => {
                            console.log(formView.getFieldValue('viewLocationField'));
                            
                            const viewLocation = formView.getFieldValue('viewLocationField');
                            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
                            if (regex.test(viewLocation)) {
                                console.log(viewLocation);
                                
                                const [lat, lon] = viewLocation.split(',').map(parseFloat);
                                console.log('lat', lat, lon);
                                formView.setFields([
                                    {
                                        name: 'viewLocationField',
                                        errors: []
                                    }
                                ])
                                setMarkersView({
                                    lat,
                                    lon,
                                })
                                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
                            }
                        }}
                         className="bg-base text-white mr-2">Xem</Button>
                    </Row>
                </Form>

                <Button onClick={() => {
                    setIsShowMarker(!isShowMarker);
                }}>{!isShowMarker ? `Show Marker` : `Hide Marker`}</Button>
                <Upload
                    accept='.json'
                    beforeUpload={file => {
                        const reader = new FileReader();

                        reader.onload = (e: any) => {
                            const coordinatesArray = JSON.parse(e.target.result).coordinates
                            const bounds = new LngLatBounds();
                            coordinatesArray.forEach((coord: any) => bounds.extend(new LngLat(coord[0], coord[1])));
                            mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
                            const features = {
                                type: 'Feature',
                                properties: {
                                    color: "red",
                                    index: 0
                                },
                                geometry: {
                                    type: 'LineString',
                                    coordinates: coordinatesArray,
                                },
                            };
                            setLineCreate(features);
                        };
                        reader.readAsText(file);

                        // Prevent upload
                        return false;
                    }}
                >
                    <Button icon={<UploadOutlined />}>Upload file Json</Button>

                </Upload>
                <Upload
                    accept='.json'
                    beforeUpload={file => {
                        const reader = new FileReader();

                        reader.onload = async (e: any) => {
                            const coordinatesArray = JSON.parse(e.target.result).coordinates

                            const payload = {
                                "costing": "auto",
                                "costing_options": {
                                    "auto": {
                                        "exclude_polygons": [],
                                        "maneuver_penalty": 5,
                                        "country_crossing_penalty": 0,
                                        "country_crossing_cost": 600,
                                        "width": 1.6,
                                        "height": 1.9,
                                        "use_highways": 1,
                                        "use_tolls": 1,
                                        "use_ferry": 1,
                                        "ferry_cost": 300,
                                        "use_living_streets": 0.5,
                                        "use_tracks": 0,
                                        "private_access_penalty": 450,
                                        "ignore_closures": false,
                                        "closure_factor": 9,
                                        "service_penalty": 15,
                                        "service_factor": 1,
                                        "exclude_unpaved": 1,
                                        "shortest": false,
                                        "exclude_cash_only_tolls": false,
                                        "top_speed": 140,
                                        "fixed_speed": 0,
                                        "toll_booth_penalty": 0,
                                        "toll_booth_cost": 15,
                                        "gate_penalty": 300,
                                        "gate_cost": 30,
                                        "include_hov2": false,
                                        "include_hov3": false,
                                        "include_hot": false,
                                        "disable_hierarchy_pruning": false,
                                        "use_trails": 0
                                    }
                                },
                                "exclude_polygons": [[]],
                                "directions_options": { "units": "kilometers" },
                                "locations": coordinatesArray.map((item: any) => ({
                                    lat: item[1],
                                    lon: item[0],
                                    type: 'via'
                                }))
                                ,
                                "id": "valhalla_directions"
                            }
                            const valhalla_url = "https://valhalla1.openstreetmap.de/route"

                            try {

                                const response = await axios.post(valhalla_url, payload);
                                if (response.status === 200) {
                                    const data = response.data;
                                    let linestring = null;

                                    for (const leg of data.trip.legs) {
                                        const geometry = leg.shape || [];
                                        if (geometry.length > 0) {
                                            linestring = decodeGeometry(geometry);
                                            break;
                                        }
                                    }
                                    const features = {
                                        type: 'Feature',
                                        properties: {
                                            color: "red",
                                            index: 0
                                        },
                                        geometry: linestring,
                                    };
                                    setLineCreate(features);
                                } else {
                                    console.error(`Error get_valhalla_route: ${response.status}`);
                                    console.error(response.data);
                                    return null;
                                }
                            } catch (error) {
                                console.error('Error get_valhalla_route:', error);
                                return null;
                            }

                        };
                        reader.readAsText(file);

                        // Prevent upload
                        return false;
                    }}
                >
                    <Button icon={<UploadOutlined />}>Upload file Json to Valhalla</Button>

                </Upload>
            </div>
        

            <Map
                style={{ flex: 4 }}
                initialViewState={initialViewState}
                mapStyle={`mapbox://styles/mapbox/streets-v12`}
                ref={mapRef as React.Ref<MapRef>}
                mapboxAccessToken={MAPBOX_TOKEN}
                interactiveLayerIds={['search-route']}
                maxZoom={18}

            >
                {markersView && <Marker  latitude={markersView.lat} longitude={markersView.lon}>
                <div >
                        <img src='./pin-start.png'>
                        </img>
                    </div>
                    </Marker>}
                {lineCreate && <Source id="grid" type="geojson" data={lineCreate}>
                    <Layer {...layerSearchRouteStyle} >
                    </Layer>
                </Source>}
                {isShowMarker && lineCreate && lineCreate.geometry.coordinates.map((item: any, index: number) => {
                    return <Marker
                        onClick={(e) => {
                            console.log(item[1], item[0]);
                            e.originalEvent.stopPropagation();
                        }}
                        key={item[1]} latitude={item[1]} longitude={item[0]}></Marker>
                })}
                {markersStart && (<Marker
                    key={`marker-start`}
                    latitude={markersStart?.lat}
                    longitude={markersStart?.lon}
                    style={{ color: 'red' }}
                    draggable={true}
                    onDragEnd={(event) => {
                        setMarkersStart({ ...markersStart, lat: event.lngLat.lat, lon: event.lngLat.lng });
                        formSample.setFieldsValue({
                            srcLocation: `${event.lngLat.lat},${event.lngLat.lng}`
                        });
                    }}
                >
                    <div >
                        <img src='./pin-start.png'>
                        </img>
                    </div>
                </Marker>)}
                {markersEnd && (<Marker
                    key={`marker-end`}
                    latitude={markersEnd?.lat}
                    longitude={markersEnd?.lon}
                    onDragEnd={(event) => {
                        setMarkersEnd({ ...markersEnd, lat: event.lngLat.lat, lon: event.lngLat.lng })
                        formSample.setFieldsValue({
                            viewLocation: `${event.lngLat.lat},${event.lngLat.lng}`
                        })
                    }}
                    draggable={true}
                >
                    <div >
                        <img src='./pin-end.png'>
                        </img>
                    </div>
                </Marker>)}
            </Map>
        </div>
    )
}