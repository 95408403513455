import { FillLayer, LayerProps, LineLayer } from "react-map-gl";
import { CMarker, IMarker, ITitle, ITrafficType, LinkState } from "./type";
export const APP_COLOR = { base: '#3D8A55', warning: '#FF5454' }
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiaGllbm52IiwiYSI6ImNsdWNoNXk4NTE1bnMyaHF1aG00czFzMjQifQ.lW9sDNKzDZFOfJ5yyELcoA'; // Set your mapbox token here
export const STORAGE_URL = `https://api-storage-staging.gofa.vn/`
export const VALID_IP = '113.177.27.162'
export const NOMINATIM_URL="https://api-prod.gofa.vn/gateway/nominatim-v3/details.php?osmtype=W&class=highway&addressdetails=1&hierarchy=0&group_hierarchy=1&polygon_geojson=1&format=json"
export const STAGING_NOMINATIM_URL = "https://nominatim-staging.gofa.vn/reverseV2"
export const DEFAULT_VALUE_TRAFFIC_SIGN =
  [
    {
      name: 'speed',
      value: [30, 40, 50, 60, 70, 80, 90, 100, 110, 120]
    },
    {
      name: 'traffic-sign',
      value: ['Entering Urban Area',
        'Town Exit Point',
        'Toll Booth',
        'Start of No Overtaking',
        'End of No Overtaking']
    }
    ,
    {
      name: 'camera',
      value: ['Speed Camera',
        'Mobile Speed Camera',
        'Traffic Camera',
        'Speed Trap Area',
        'Red Light Camera',
        'Red Light and Speed Camera']
    },
    {
      name: 'others',
      value: null
    },
    {
      name: 'all',
      value: null
    }
  ]

export const initialViewState = {
  longitude: 105.8342,
  latitude: 21.0278,
  zoom: 12,
  bearing: 0,
  pitch: 0
};
export const IMARKER_TYPES: IMarker = {
  normal: 'normal',
  start: 'start',
  end: 'end',
  marker: 'marker',
  endUpdate: 'endUpdate',
  center: 'center',
  create: 'create',
};
export const kindOfWayDirection = [{ value: 'chieu_ve', label: 'chieu_ve' }, { value: 'chieu_di', label: 'chieu_di' }]

export const CMARKER_TYPES: CMarker = {
  start: 'startCreate',
  end: 'endCreate',
  reGenStart: 'reGenStart',
  reGenEnd: 'reGenEnd',
  other: 'other',
  updateStart: 'updateStart',
  updateEnd: 'updateEnd',
};
export const MAP_STYLE = {
  version: 8,
  sources: {
    "raster-tiles": {
      type: "raster",
      tiles: [
        "https://tile.openstreetmap.org/{z}/{x}/{y}.png"
      ],
      tileSize: 256
    }
  },
  layers: [
    {
      id: "osm-tiles",
      type: "raster",
      source: "raster-tiles",

    }
  ]
};
export const defaultAudioLink = [
  {
    "type": "traffic-jam",
    "audio_link": "api.storage.staging.gofa.vn/admin/text_to_speech/fixed_audio/traffic_jam.mp3"
  },
  {
    "type": "flood",
    "audio_link": "api.storage.staging.gofa.vn/admin/text_to_speech/fixed_audio/flood.mp3"
  },
  {
    "type": "accident",
    "audio_link": "api.storage.staging.gofa.vn/admin/text_to_speech/fixed_audio/accident.mp3"
  },
  {
    "type": "restricted_road",
    "audio_link": "api.storage.staging.gofa.vn/admin/text_to_speech/fixed_audio/restricted_road.mp3"
  }
]
export const TrafficType: ITrafficType = {
  trafficJam: {
    name: 'Traffic Jam',
    content: 'Đoạn đường này đang xảy ra ùn tắc',
  },
  accident: {
    name: 'Accident',
    content: 'Đoạn đường này đang xảy ra tai nạn',
  },
  restrictedRoad: {
    name: 'Restricted Road',
    content: 'Đoạn đường này đã bị cấm',
  },
  flood: {
    name: 'Flood',
    content: 'Đoạn đường này đang xảy ra ngập lụt',
  },
  other: {
    name: 'Others',
    content: '...',
  },
};
export const initPopupTrafficValue = {
  id: 0,
  lat: null,
  lon: null,
  geometry: null,
  bounding_box: null,
  top_left: null,
  top_right: null,
  bottom_right: null,
  bottom_left: null,
  osm_combination_id: null,
  ai_road_id: null,
  type: "",
  name: null,
  sta_cty: null,
  content: "",
  audio_link: null,
  expire_time: 0,
  image: [],
  created_at: 0,
  updated_at: 0,
  traffic_type: "",
  forever: false,
  display: false,
  bearing: 0,
}
export const trafficMappings: { [key: string]: { linkPathname: string; linkState: LinkState } } = {
  'Entering Urban Area': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Entering Urban Area',
    },
  },
  'Town Exit Point': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Town Exit Point',
    },
  },
  'Start of No Overtaking': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Start of No Overtaking',
    },
  },
  'End of No Overtaking': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'End of No Overtaking',
    },
  },
  'Camera': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'camera',
      instruction: 'Traffic Camera',
    },
  },
  'Traffic Jam': {
    linkPathname: '/RealtimeTraffic',
    linkState: {
      category: 'Traffic Jam',
      content: TrafficType.trafficJam.content,
    },
  },
  'Accident': {
    linkPathname: '/RealtimeTraffic',
    linkState: {
      category: 'Accident',
      content: TrafficType.accident.content,
    },
  },
  'Flood': {
    linkPathname: '/RealtimeTraffic',
    linkState: {
      category: 'Flood',
      content: TrafficType.flood.content,
    },
  },
};
export const trafficMappingsV3: { [key: string]: { linkPathname: string; linkState: LinkState } } = {
  '14': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      // instruction: 'Biển báo cấm vượt',
      instruction: 'Start of No Overtaking',
      isSimilarWithV2: true,
      idMapping: '14',
      content: 'Cấm vượt'
    },
  },
  '15': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      // instruction: 'Biển báo hết cấm vượt',
      instruction: 'End of No Overtaking',
      isSimilarWithV2: true,
      idMapping: '15', 
      content: 'Hết cấm vượt'
    },
  },
  '12': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Entering Urban Area',
      idMapping: '12',
      content: 'Vào khu dân cư'
    },
  },
  '13': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Town Exit Point',
      idMapping: '13',
      content: 'Thoát khỏi khu dân cư'
    },
  },
  '28': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Biển báo cấm đỗ',
      idMapping: '28',
      content: 'Cấm đỗ'
    },
  },
  '29': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Biển báo cấm dừng đỗ',
      idMapping: '29',
      content: 'Cấm dừng đỗ'
    },
  },
  '30': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Biển báo cấm đỗ ngày chẵn',
      idMapping: '30',
      content: 'Cấm đỗ ngày chẵn'
    },
  },
  '31': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Biển báo cấm đỗ ngày lẻ',
      idMapping: '31',
      content: 'Cấm đỗ ngày lẻ'
    },
  },
  '21': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Biển báo cấm rẽ trái',
      idMapping: '21',
      content: 'Cấm rẽ trái'
    },
  },
  '22': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Biển báo cấm rẽ phải',
      idMapping: '22',
      content: 'Cấm rẽ phải'
    },
  },
  '25': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Biển báo cấm quay đầu trái',
      idMapping: '25',
      content: 'Cấm quay đầu trái'
    },
  },
  '26': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Biển báo cấm quay đầu phải',
      idMapping: '26',
      content: 'Cấm quay đầu phải'
    },
  },
  '23': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Biển báo cấm rẽ trái và quay đầu',
      idMapping: '23',
      content: 'Cấm rẽ trái và quay đầu'
    },
  },
  '24': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'traffic-sign',
      instruction: 'Biển báo cấm rẽ phải và quay đầu phải',
      idMapping: '24',
      content: 'Cấm rẽ phải và quay đầu'
    },
  },
  '32': {
    linkPathname: '/DataSystem',
    linkState: {
      category: 'camera',
      // instruction: 'Camera giao thông',
      instruction: 'Traffic Camera',
      isSimilarWithV2: true,
      idMapping: '32',
      content: 'Camera giao thông'
    },
  },
  '36': {
    linkPathname: '/RealtimeTraffic',
    linkState: {
      category: 'Traffic Jam',
      isSimilarWithV2: true,
      content: TrafficType.trafficJam.content,
    },
  },
  '35': {
    linkPathname: '/RealtimeTraffic',
    linkState: {
      category: 'Accident',
      isSimilarWithV2: true,

      content: TrafficType.accident.content,
    },
  },
  '37': {
    linkPathname: '/RealtimeTraffic',
    linkState: {
      category: 'Flood',
      isSimilarWithV2: true,

      content: TrafficType.flood.content,
    },
  },
};

export const ITITLE_TYPES: ITitle = {
  trafficNews: 'Bản tin giao thông',
  lineTraffic: 'Line ùn tắc'
}
export const I_TYPES: ITitle = {
  trafficNews: 'Traffic Bulletin',
  lineTraffic: 'Traffic congestion point'
}
export const layerStyle: LineLayer = {
  id: 'data',
  type: 'line',
  paint: {
    'line-color': APP_COLOR.warning,
    'line-width': 8,
  },
  source: 'my-data',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
};

export const layerCongestionStyle: LineLayer = {
  id: 'data',
  type: 'line',
  paint: {
    'line-color': ['get', 'color'],
    'line-width': 8,

  },
  source: 'my-data',

  // layout: {
  //   'line-join': 'round',
  //   'line-cap': 'round'
  // },
};

export const layerCreateCongestionStyle: LineLayer = {
  id: 'create',
  type: 'line',
  paint: {
    'line-color': ['get', 'color'],
    'line-width': 8,
  },
  source: 'my-data',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
};

export const layerSearchRouteStyle: LineLayer = {
  id: 'search-route',
  type: 'line',
  paint: {
    'line-color': ['get', 'color'],
    'line-width': 8,
    'line-opacity': 0.5,
  },
  source: 'my-data',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
};
export const layerGGM: LineLayer = {
  id: 'GGM',
  type: 'line',
  paint: {
    'line-color': ['get', 'color'],
    'line-width': 8,
    'line-opacity': 0.5,
  },
  source: 'GGM',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
};
export const layerCreateStyle: LineLayer = {
  id: 'create',
  type: 'line',
  paint: {
    'line-color': APP_COLOR.base,
    'line-width': 8,
  },
  source: 'my-data',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
};

export const defaultLineCongestion = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        color: '#F19C5C',
        level: 'level1'
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [],
      }
    },
    {
      type: 'Feature',
      properties: {
        color: '#DF4D3E',
        level: 'level2'

      },
      geometry: {
        type: "MultiLineString",
        coordinates: []
      }
    },
    {
      type: 'Feature',
      properties: {
        color: '#772723',
        level: 'level3'
      },
      geometry: {
        "type": "MultiLineString",
        "coordinates": []
      }
    },
  ],

}

export const layerGridStyle: FillLayer = {
  id: 'grid',
  type: 'fill',
  source: 'grid',
  paint: {
    'fill-color': APP_COLOR.base, // blue color fill
    'fill-opacity': 0.5,
    'fill-outline-color': 'red',
  }
};


export const layerSupiciousStyle: FillLayer = {
  id: 'grid',
  type: 'fill',
  source: 'grid',
  paint: {
    'fill-color': APP_COLOR.base, // blue color fill
    'fill-opacity': 0.5,
    'fill-outline-color': 'red',
  }
};

export const pointLayer: LayerProps = {
  id: 'point',
  type: 'circle',
  paint: {
    'circle-radius': 10,
    'circle-color': APP_COLOR.warning
  }
};



export const layerGeohashStyle: LineLayer = {
  id: 'grid',
  type: 'line',
  paint: {
    'line-color': APP_COLOR.base,
    'line-width': 3,
  },
  source: 'my-data',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
};

export const lineRelation : LineLayer = {
  id: 'lineRelation',
  type: 'line',
  paint: {
    'line-color': [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "yellow",
      ['get', 'color']
    ],
    'line-width': {
      'stops': [
        [0, 1],
        [2, 10]
      ],
      'default':1,
      'type': 'exponential'

    },


  },
  source: 'lineRelation'
};

export const optionPointV2 = [
  {
      value: '3',
      label: 30
  },
  {
      value: '4',
      label: 40
  },
  {
      value: '5',
      label: 50
  },
  {
      value: '6',
      label: 60
  },
  {
      value: '7',
      label: 70
  },
  {
      value: '8',
      label: 80
  },
  {
      value: '9',
      label: 90
  },
  {
      value: '10',
      label: 100
  },
  {
      value: '11',
      label: 120
  },
  {
      value: '12',
      label: 'Vào khu dân cư'
  },
  {
      value: '13',
      label: 'Thoát khỏi khu dân cư'
  },
  {
      value: '14',
      label: 'Cấm vượt'
  },
  {
      value: '15',
      label: 'Hết cấm vượt'
  },
  {
      value: '16',
      label: 'Đi chậm'
  },
  {
      value: '17',
      label: 'Trạm dừng nghỉ'
  },
  {
      value: '18',
      label: 'Trạm thu phí'
  },
  {
      value: '19',
      label: 'Tàu hoả'
  },
  {
      value: '20',
      label: 'Hầm chui'
  },
  {
      value: '21',
      label: 'Cấm rẽ trái '
  },
  {
      value: '22',
      label: 'Cấm rẽ phải'
  },
  {
      value: '23',
      label: 'Cấm rẽ trái và quay đầu'
  },
  {
      value: '24',
      label: 'Cấm rẽ phải và quay đầu'
  },
  {
      value: '25',
      label: 'Cấm quay đầu trái'
  },
  {
      value: '26',
      label: 'Cấm quay đầu phải'
  },
  {
      value: '27',
      label: 'Cấm đi thẳng'
  },
  {
      value: '28',
      label: 'Cấm đỗ'
  },
  {
      value: '29',
      label: 'Cấm dừng đỗ'
  },
  {
      value: '30',
      label: 'Cấm đỗ ngày chẵn'
  },
  {
      value: '31',
      label: 'Cấm đỗ ngày lẻ '
  },
  {
      value: '32',
      label: 'Camera giao thông'
  },
  {
      value: '33',
      label: 'Camera phạt nguội'
  },
  {
      value: '34',
      label: 'Camera vượt đèn đỏ '
  },
  {
      value: '35',
      label: 'Cấm đi ngược chiều'
  },

]