import { Button, Col, Form, FormInstance, Input, Row, Select, Typography } from 'antd';
import { FC } from 'react';
import * as React from 'react';

import { APP_COLOR, IMARKER_TYPES } from '../../../constant';
import { AiRoadIdOptions, MapPoint, MarkerType, TrafficSignOptions } from '../../../constant/type';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import { getMapPoint, getRoadName } from '../../../services';
import { MapRef } from 'react-map-gl';
interface HandleFormViewFinish {
    (data: any): void;
}
interface PProps {
    formView: FormInstance<any>;
    notFoundRoad: boolean
    aiRoadId: AiRoadIdOptions | undefined,
    setFilterMarker: React.Dispatch<React.SetStateAction<boolean>>,
    setKindOfPoint: React.Dispatch<React.SetStateAction<string | undefined>>,
    kindOfSign: {
        value: string;
        label: string;
    }[],
    kindOfSignAll: {
        value: string;
        label: string;
    }[],
    trafficSignValue: TrafficSignOptions | undefined,
    ImarkerType: string,
    handleDeleteMapPoint: () => void
    kindOfPoint: string | undefined,
    handleClearFilter: () => void,
    setNotFoundRoad: (value: React.SetStateAction<boolean>) => void,
    mapRef: React.RefObject<MapRef>,
    setMarkers: (value: React.SetStateAction<MarkerType | undefined>) => void,
    setPoints: (value: any) => void
}
export const ViewPoint: FC<PProps> = ({ formView,
    notFoundRoad,
    aiRoadId,
    setFilterMarker,
    setKindOfPoint,
    kindOfSignAll,
    trafficSignValue,
    ImarkerType,
    handleDeleteMapPoint,
    kindOfPoint,
    handleClearFilter,
    setNotFoundRoad,
    mapRef,
    setMarkers,
    setPoints
}) => {
    const { Text } = Typography;
    const handleKeyUp = (event: any) => {
        if (event.keyCode === 13) {
            formView.submit();
        }
    }
    const handleFormViewFinish = (data: any) => {
        const [lat, lon] = data.VLocation.split(',').map(parseFloat);

        mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
        getMapPoint({ lat: lat, lon: lon }).then((res: MapPoint[]) => {
            if (res.length > 0) {
                const bounds = new LngLatBounds();
                res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
            }
            setPoints(res);

        })
        getRoadName({ lat: lat, lon: lon }).then((res: any) => {
            if (res.name) {
                formView.setFieldsValue({
                    name: res.name,
                });
                setNotFoundRoad(false)

            } else {
                formView.resetFields(['name']);
                setNotFoundRoad(true)
            }

        }).catch((err) => {
            setNotFoundRoad(true);
            formView.resetFields(['name']);
        })
        setMarkers({ lat: lat, lon: lon })

    }
    return <div>
        <Form onFinish={handleFormViewFinish} form={formView} onKeyUp={handleKeyUp} layout='vertical'>
            <Form.Item required name='VLocation' rules={[
                {
                    required: true,
                    validateTrigger: 'onFinish',
                    message: 'Vui lòng chọn/nhập toạ độ'
                },
            ]} >
                <Input allowClear placeholder='Nhập toạ độ (vĩ độ - lat, kinh độ - lon)' />
            </Form.Item>
            <Form.Item name='name' label='Tên đường' >
                <Input allowClear placeholder='Tên đường' />
            </Form.Item>
            <Text type="danger">{notFoundRoad && 'Không tìm thấy tên đường'}</Text>
            <Form.Item name='ai_road_id' label='AI Road Id'>
                <Select showSearch options={aiRoadId} onChange={(value) => {
                    setFilterMarker(false);
                    formView.resetFields(['category', 'instruction']);
                }}></Select>
            </Form.Item>
            <Form.Item name='category' label='Loại biển' >
                <Select onChange={(value) => {
                    setKindOfPoint(value);
                    formView.resetFields(['instruction']);
                }}
                    options={kindOfSignAll}></Select>
            </Form.Item>
            <Form.Item name='instruction' label='Giá trị biển'>
                <Select options={trafficSignValue}></Select>
            </Form.Item>
        </Form>
        <div className='flex flex-row justify-end'>
            {ImarkerType == IMARKER_TYPES.marker &&
                (<div className='flex flex-row justify-start'>
                    <Button onClick={handleDeleteMapPoint} className='bg-warning text-white mr-2'>Xoá điểm</Button>
                </div>)}
            {kindOfPoint && <Button
                className='bg-base text-white'
                onClick={handleClearFilter}>Clear filter</Button>}
        </div>
    </div>
}