import { Button, List, Table, message } from "antd";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { FeedbackData } from "../../constant/type";
import { getFeedback } from "../../services";
import { FeebackList } from "../feedback/component/FeedbackList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Rank = () => {
    console.log(process.env.REACT_APP_BASE_URL)
    const [rankData, setRankData] = useState<any>([]);
    const [feedbackData, setFeedbackData] = useState<FeedbackData[]>([]);
    const [sortedFeedbackData, setSortedFeedbackData] = useState<FeedbackData[]>([]);
    const [show, setShow] = useState<boolean>(false);
    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                const response: AxiosResponse<FeedbackData[]> = await getFeedback({ sort: 'newest' });
                const phoneOccurrences: { [key: string]: number } = {};
                for (const feedback of response.data) {
                    const customerPhone = feedback.customerPhone;
                    if (customerPhone) {
                        if (phoneOccurrences[customerPhone]) {
                            phoneOccurrences[customerPhone]++;
                        } else {
                            phoneOccurrences[customerPhone] = 1;
                        }
                    }
                }
                // Chuyển đổi mảng phoneOccurrences thành mảng các đối tượng và sắp xếp giảm dần theo số lần xuất hiện
                const sortedPhoneOccurrences = Object.entries(phoneOccurrences)
                    .map(([customerPhone, occurrences]) => ({ customerPhone, occurrences }))
                    .sort((a, b) => b.occurrences - a.occurrences)
                    .slice(0, 20); // Lấy tối đa 20 kết quả
                console.log('sortedPhoneOccurrences', sortedPhoneOccurrences);
                setFeedbackData(response.data)
                setRankData(sortedPhoneOccurrences as any);
            } catch (error) {
                message.error('Lỗi khi lấy dữ liệu góp ý');
                console.error(`Failed to fetch feedback: ${error}`);
            }
        };
        fetchFeedback();
    }, []);

    return <div className="flex flex-col p-4 w-full">
        {
            show ?
                <div
                >
                    <h1 className="text-lg">
                        <Button ghost onClick={() => setShow(false)}>
                            <FontAwesomeIcon icon="angle-left"></FontAwesomeIcon>
                        </Button>

                        Chi tiết tài khoản</h1>
                    <List pagination={{
                        position: 'bottom',
                        pageSize: 20, align: 'center',
                    }}
                        dataSource={sortedFeedbackData}
                        rowKey={'id'}
                        renderItem={(item) => FeebackList({ item })}
                    />
                </div>
                :
                <div>
                    <h1 className="font-bold text-lg" >Rank</h1>
                    <Table
                        tableLayout='auto'
                        style={{ height: 'auto', cursor: "pointer" }}
                        dataSource={rankData}
                        rowKey={'customerPhone'}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => {
                                    console.log(record.customerPhone);
                                    const sortedFeedbackData = feedbackData.filter((feedback: FeedbackData) => feedback.customerPhone === record.customerPhone);
                                    setSortedFeedbackData(sortedFeedbackData);
                                    setShow(true);
                                },
                            };
                        }}
                        columns={[
                            {
                                title: "Thứ hạng",
                                render: (text, record, index) => <h4 style={{ fontSize: 14 }}>{index + 1}</h4>,
                                key: 'id',
                            },
                            {
                                title: "Số điện thoại",
                                render: (text, record, index) => <p>{record.customerPhone.replace('+84', '0')}</p>,
                                key: 'customerPhone',
                            },
                            {
                                title: "Số lần góp ý",
                                dataIndex: 'occurrences',
                                key: 'occurrences',
                            },

                        ]}
                    >

                    </Table>
                </div>
        }
    </div>
};

export default Rank;